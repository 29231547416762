<template>
    <div class="opacity-page table">
        <div class="row-space-tbf list-dashboard">
			<div class="space-left"></div>
			<div class="content dashboard">
				<div class="items dashboard banks">
					<div v-for="(bank, key) in banks" :id="'item_bank_' + key" class="item">
						<div class="container column">
							<div v-if="bank.file_path" class="icon full-width">
								<img :src="bank.file_path">
							</div>
                            <div v-else class="icon full-width">
								<h1>{{bank.short_name}}</h1>
							</div>
                            <div class="details">
								<div class="value">
                                    <button :id="'buttonGenerateFile'+key" @click="generateFile(bank.id, key)" class="btn-tbf blue">
                                        <div class="loader"></div>
                                        <span class="text">{{ $t('general.generate') }}</span>
                                    </button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
        </div>
    </div>
</template>

<script>
	import IconPlus from '../../../Icons/Plus'
	import IconMinus from '../../../Icons/Minus'

	export default {
		components: {
			IconPlus,
			IconMinus
		},
		data() {
			return {
				loaded: false,
                banks: [],
			}
		},
		async mounted(){
            if(this.$route.params.data) {
				this.payroll_date = this.$route.params.data
                this.getBanks();
			}
            setTimeout(() => {
				var title = this.$t('salary-navbar.bank_files');
				this.$root.$emit("navbar_title", title);
			}, 0)
            
            
            this.$root.$on('refreshSalaryIndex', (data) => {
                this.payroll_date = data
				this.getBanks()
			})
		},
        beforeDestroy() {
			this.$root.$off('refreshSalaryIndex')
        },

		methods: {
            async getBanks() {
                await axios.get(`${this.$auth.user().instance.id}/banks/used-bank`)
				.then(({data}) => {
                    this.banks = data.data
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => { 
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },
            async generateFile(file, key) {
                var buttonName = 'buttonGenerateFile'+key
				var btnSubmit = document.getElementById(buttonName)
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                
				await axios.get(`/banks/export-file/${file}`, 
				{
                	responseType: 'blob'
				})
				.then((response) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.generate')
                            btnSubmit.disabled = false

                            let blob = new Blob([response.data], { type:"application/octet-stream" })
                            let fileURL = window.URL.createObjectURL(blob);

                            let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];

                            let fileLink = document.createElement('a');
                            fileLink.href = fileURL;
                            fileLink.setAttribute( 'download', fileName );
                            document.body.appendChild(fileLink);

                            fileLink.click();
						}, 1000)
					}, 300)

					
				}).catch(error => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.generate')
							btnSubmit.disabled = false
                            this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
                })
            }
		}
	}
</script>